.PageHeader {
    text-align: center;
    text-transform: none;
    padding-left: 10%;
    padding-right: 10%;
    font-family: "Bree Serif", Artbrush;
    margin-bottom: 50px;
    margin-top: 50px;
}

.PageHeader h1 {
    font-size: 55px;
    margin: 0;
}

@media only screen and (max-width: 600px) {
    .PageHeader h1 {
        font-size: 40px;
    }
}