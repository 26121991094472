@font-face {
    font-family: "IBM";
    src: url("../../meta/fonts/IBMPlexSans-Light.ttf") format("truetype");
}

@font-face {
    font-family: "Artbrush";
    src: url("../../meta/fonts/Artbrush.ttf") format("truetype");
}

.NavMenu {
    height: 100vh;
    top: 0;
    left: 0;
}

.NavMenuItemsContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 5vh;
    height: 100%;
}

.NavMenuItem {

}

.NavMenu .navmenu-button {
    color: var(--primary-text);
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: var(--primary-text);
    background-color: transparent;
    border: none;
    padding: 12px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-family: 'Bree Serif', Helvetica, Arial, sans-serif;
    font-size: 80px;
    letter-spacing: -2px;
    cursor: pointer;
    transition: all 0.25s ease;
}

.NavMenu .navmenu-button:focus {
    outline: none;
}

.NavMenu .navmenu-button:hover {
    transform: translate(-5px);
}

.NavMenu .active {
    color: var(--primary-color);
    font-weight: bold;
}

.NavMenu .school-nav:hover {
    text-shadow: 5px 5px var(--primary-color);
}

.NavMenu .gameboy-nav.active {
    color: var(--gameboy-color);
}

.NavMenu .gameboy-nav:hover {
    text-shadow: 5px 5px var(--gameboy-color);
}

.NavMenu .music-nav.active {
    color: var(--music-color);
}

.NavMenu .music-nav:hover {
    text-shadow: 5px 5px var(--music-color);
}

.gameboy-nav {
    
}

.school-nav {
    
}

.music-nav {
    
}

@media only screen and (max-width: 600px) {
    .NavMenu .navmenu-button {
        font-size: 45px;
    }
}