.lightTheme {
    --background: #F3F2F7;
    --overlay-background: #e5e5ea;
    --shadow-background: #8e8e93;
    --primary-text: #1c1c1e;
    --primary-color: #68DE86;
    --gameboy-color: #5CADFF;
    --music-color: #fed846;
    --header-background: #1c1c1e;
    --header-text: #F3F2F7;
    --music-line-and-text: #1c1c1e;
    --header-primary: #fed846;
    --header-secondary: #68DE86;
    --header-third: #5CADFF;
    --header-name: #1c1c1e;
    --menu-background: #F3F2F7;
}

.darkTheme {
    --background: #1c1c1e;
    --overlay-background: #3a3a3c;
    --shadow-background: #636366;
    --primary-text: #F3F2F7;
    --primary-color: #68DE86;
    --gameboy-color: #5CADFF;
    --music-color: #fed846;
    --header-background: #F3F2F7;
    --header-text: #1c1c1e;
    --music-line-and-text: #1c1c1e;
    --header-primary: #fed846;
    --header-secondary: #68DE86;
    --header-third: #5CADFF;
    --header-name: #1c1c1e;
}