.Footer {
    color: var(--primary-text);
    background-color: var(--background);
    display: flex;
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
    box-shadow: 5px 5px 5px var(--shadow-background);
    border: solid 3px var(--primary-text);
}

.footer-about-me {
    width: 60%;
    padding-left: 2%;
    padding-right: 2%;
    padding-bottom: 2%;
    font-size: 20px;
}

.footer-links {
    width: 40%;
}

.footer-links a {
    color: var(--primary-text);
    text-decoration: none;
}

.footer-title {
    font-family: 'Bree Serif', Artbrush;
    font-size: 20px;
}

.footer-links a:hover {
    color: var(--primary-color);
}

.footer-links .footer-signature {
    width: 100%;
    font-family: Artbrush;
    font-size: 50px;
    margin: 5px;
}

.Copyrights {
    padding-top: 10px;
    padding-bottom: 20px;
    text-align: center;
}

@media only screen and (max-width: 600px) {
    .Footer {
        display: block;
    }
    .footer-about-me {
        text-align: center;
        width: 80%;
        padding-left: 10%;
        padding-right: 10%;
    }
    .footer-links {
        width: 100%;
        text-align: center;
    }
}