.ProjectImage {
    text-align: center;
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
    width: 35%;
    min-width: 250px;
}

.ProjectImage img {
    width: 100%;
    border: solid 3px var(--primary-text);
    border-radius: 10px;
}

.imgLG {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
}

.imgTIPE {
    width: 40%;
    margin-left: auto;
    margin-right: auto;
}

.imgTIPE_2 {
    width: 20%;
    margin-left: auto;
    margin-right: auto;
}

.ProjectImage h4 {
    font-size: 15px;
    font-weight: bold;
    font-style: italic;
}