.welcome {
    text-align: center;
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.welcome-text {
width: 100%;
    text-align: left;
    font-family: "Bree Serif";
    color: var(--primary-text);
}

.welcome-text h1 {
    font-size: 40px;
}

.welcome-img {
    width: 50%;
}

.welcome-img img {
    width:60%;
    padding: 10px 12px;
}

@media only screen and (max-width: 900px) {
    .welcome-text {
        text-align: center;
        width: 100%;
    }

    .welcome-text h1 {
        font-size: 30px;
    }

    .welcome-img {
        width: 100%;
    }
}