@font-face {
    font-family: "Artbrush";
    src: url("../../meta/fonts/Artbrush.ttf") format("truetype");
}

.project-desc {
    font-size: 20px;
    padding-top: 20px;
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 100px;
}

.project-desc a {
    font-weight: bold;
    font-style: italic;
    text-decoration: none;
    color: black;
}

.project-desc a:hover {
    font-weight: bold;
    color: var(--primary-color);
}

.project-desc.music-page a:hover {
    color: var(--music-color);
}

.project-desc h2 {
    color: var(--primaty-text);
    font-family: "Bree Serif", Artbrush;
    font-size: 35px;
}

.project-desc.music-page h2 {
    color: var(--music-color);
}

.imgContainer {
    display: flex;
}

.ImageFlexbox {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.music-yt-video {
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (max-width: 780px) {
    .music-yt-video {
        width: 100% !important;
    }

    .project-desc h2 {
        font-size: 25px;
    }
}