.albumChronologyMainContainerTitle {
    position: -webkit-sticky;
    /* Required for Safari */
    position: sticky;
    top: 0;
    width: 100%;
    text-align: center;
    margin-bottom: 340px;
    background-color: transparent;
    z-index: 9;
}


.albumChronologyMainContainer h2 {
    font-family: "Bree Serif", Artbrush;
    font-size: 45px;
    color: var(--primary-text);
    text-shadow: 5px 5px #80f8bc98;
    background-color: var(--background);
    padding-bottom: 10px;
    margin: 0;
    width: 100%;
}

.albumChronologyMainContainerTitleSvg {
}

.albumChronologyMainContainer {
    background-color: var(--primary-color);
    margin-top: 7vw;
}

.albumChronologyContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    text-align: center;
    margin-top: -340px;
}

.albumContainer {
    width: 250px;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
}

.albumImage {
    width: 100%;
}

.albumImage img {
    width: 250px;
}

.albumImage .albumImageHover {
    position: absolute;
    top: 0;
    left: 0;
    width: 250px;
    height: 250px;
    background-color: var(--music-color);
    margin-left: 15px;
    opacity: 0;
    font-family: 'Bree Serif';
    color: var(--music-line-and-text);
    font-size: 15px;
}

.albumImage:hover .albumImageHover {
    opacity: 0.9;
  }

.albumTitle, .albumYear {
    color: var(--music-line-and-text);
    text-shadow: 1px 1px 2px var(--music-color);
    font-family: "Bree Serif", Artbrush;
}

.albumBottomLine {
    width: 100%;
    height: 10px;
    background-color: var(--music-line-and-text);
    margin-bottom: 20px;
}

.albumBottomCircle {
    position: relative;
    height: 30px;
    width: 30px;
    border-radius: 100%;
    background-color: var(--music-line-and-text);
    margin-left: auto;
    margin-right: auto;
    transform: translateY(-10px);
}

.albumBottomSmallCircle {
    position: relative;
    height: 10px;
    width: 10px;
    border-radius: 100%;
    background-color: var(--music-color);
    margin-left: auto;
    margin-right: auto;
    transform: translateY(10px);
}

.YearDisplay {
    position: fixed;
    top: 10vh;
    font-size: 40vw;
    width: 100%;
    text-align: center;
    font-family: 'Bree Serif';
    opacity: 0.8;
    display: none;
}

@media only screen and (max-width: 900px) {
    .albumChronologyMainContainer h2 {
        font-size: 25px;
    }
}