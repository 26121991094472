.GameBoyGif {
    text-align: center;
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
}

.GameBoyGif img {
    border: solid 3px black;
    border-radius: 10px;
}

.GameBoyGif h4 {
    font-weight: normal;
    font-style: italic;
    max-width: 200px;
    margin-left: auto;
    margin-right: auto;
}