@font-face {
    font-family: "Artbrush";
    src: url("../../meta/fonts/Artbrush.ttf") format("truetype");
}

.ProjectBox {
    border: none;
    width: 235px;
    text-align: center;
    padding-left: 5%;
    padding-right: 5%;
}

.ProjectBoxTitle {
    font-family: "Bree Serif", Artbrush;
    font-size: 24px;
}

.ProjectBox .project-box-img-container {
    border-radius: 25px;
    overflow: hidden;
    box-shadow: 5px 5px 5px var(--shadow-background);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0)
}

.ProjectBox img {
    width: 100%;
    transition: transform .5s ease;
    display: block;
}

.project-box-img-container:hover {
    cursor: pointer;
    box-shadow: 5px 5px 10px var(--primary-color)
}

.project-box-img-container:hover img {
    transform: scale(1.15);
    -webkit-transform: scale(1.15);
}