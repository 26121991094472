@font-face {
    font-family: "IBM";
    src: url("./meta/fonts/IBMPlexSans-Light.ttf") format("truetype");
}

body {
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    font-family: 'IBM', Helvetica, Arial, sans-serif;
    display: flex;
    overflow-x: hidden;
}

#root {
    overflow-x: hidden;
    position: relative;
}