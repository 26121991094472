.GameBoy-ChapterContainer {
    width: 100%;
}

.GameBoy-Menu {
    width: 10%;
    font-size: 18px;
    padding-top: 60px;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 100px;
    position: sticky;
    top: 0;
    float: left;
}

.GameBoy-MenuLink {
    width: 100%;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
}

.GameBoy-MenuLink a {
    text-decoration: none;
    color: var(--primary-text);
    font-weight: bold;
    line-height: 1.5em;
    font-family: 'Bree Serif';
    font-size: 20px;
}

.GameBoy-MenuLink a.active {
    color: var(--gameboy-color);
}

.GameBoy-MenuLink a:hover {
    border-bottom: 3px var(--primary-text) solid;
}

.GameBoy-desc {
    font-size: 20px;
    padding-top: 20px;
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 100px;
}

.GameBoy-ChapterContainer .GameBoy-desc {
    padding-left: 20%;
}

.GameBoy-desc a {
    font-family: 'Bree Serif';
    font-weight: bold;
    font-style: italic;
    text-decoration: none;
    color: var(--primary-text);
}

.GameBoy-desc a:hover {
    font-weight: bold;
    color: var(--gameboy-color);
}

.GameBoy-desc h2 {
    color: var(--gameboy-color);
    font-family: "Bree Serif", Artbrush;
    font-size: 45px;
}

.GifFlexbox {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.GameBoy-desc .chapterTitleContainer {
    display: flex;
}

.GameboyChapterLink {
    width: 60%;
    background-color: transparent;
    border: solid 5px var(--gameboy-color);
    margin-top: 10px;
    margin-bottom: 10px;
}

.GameboyChapterLink a {
    padding-left: 10px;
    font-size: 40px !important;
    font-style: normal !important;
}

.GameBoy-desc .chapterNumber {
    border-radius: 2.5rem;
    width: 1.3em;
    height: 1.3em;
    line-height: 1.1em;
    min-width: 1.3em;
    min-height: 1.3em;
    font-size: 45px;
    font-family: 'Bree serif';
    background-color: var(--gameboy-color);
    color: var(--background);
    margin: 0;
    align-self: center;
    text-align: center;
}

.GameBoy-desc .chapterTitleContainer h2 {
    padding-left: 30px;
    overflow: hidden;
}

@media only screen and (max-width: 780px) {
    .GameboyChapterLink a {
        font-size: 30px !important;
    }

    .GameBoy-ChapterContainer .GameBoy-desc {
        padding-left: 10%;
    }

    .GameBoy-Menu {
        display: none;
    }

    .GameBoy-desc h2 {
        font-size: 30px;
    }
}