@font-face {
    font-family: "IBM";
    src: url("../../meta/fonts/IBMPlexSans-Light.ttf") format("truetype");
}

@font-face {
    font-family: "Artbrush";
    src: url("../../meta/fonts/Artbrush.ttf") format("truetype");
}

.Header {
    color: var(--header-text);
    background-color: var(--header-background);
    display: flex;
    justify-content: space-between;
    background: -webkit-linear-gradient(70deg, var(--header-primary) 10%, rgba(0,0,0,0) 10%), -webkit-linear-gradient(40deg, var(--header-secondary) 25%, rgba(0,0,0,0) 25%), -webkit-linear-gradient(50deg, var(--header-third) 50%, var(--header-background) 50%);
    background: -o-linear-gradient(70deg, var(--header-primary) 10%, rgba(0,0,0,0) 10%), -webkit-linear-gradient(40deg, var(--header-secondary) 25%, rgba(0,0,0,0) 25%), -webkit-linear-gradient(50deg, var(--header-third) 50%, var(--header-background) 50%);
    background: -moz-linear-gradient(70deg, var(--header-primary) 10%, rgba(0,0,0,0) 10%), -webkit-linear-gradient(40deg, var(--header-secondary) 25%, rgba(0,0,0,0) 25%), -webkit-linear-gradient(50deg, var(--header-third) 50%, var(--header-background) 50%);
    background: linear-gradient(70deg, var(--header-primary) 10%, rgba(0,0,0,0) 10%), -webkit-linear-gradient(40deg, var(--header-secondary) 25%, rgba(0,0,0,0) 25%), -webkit-linear-gradient(50deg, var(--header-third) 50%, var(--header-background) 50%);
}

.Header .header-name {
    padding-left: 20px;
    padding-top: 8px;
    padding-bottom: 8px;
    font-family: "Bree Serif", Artbrush;
    font-size: 30px;
    /*
    background-image: url(../../meta/website-logo.png);
    background-origin: content-box;
    background-repeat: no-repeat;
    background-position: left;
    background-position-y: center;
    background-size: contain;
    */
}

.Header .header-name h2 {
    font-weight: bold;
    margin-bottom: auto;
    margin-top: auto;
    /*padding-left: 50px;*/
}

.Header .header-name a {
    color: var(--header-name);
    text-decoration: none;
}

.Header .header-name h2:hover {
    opacity: 0.3;
}

.Header .header-menu {
    text-align: end;
}

.Header .header-button {
    background-color: transparent;
    color: var(--header-text);
    border: none;
    width: 40px;
    padding: 12px 12px;
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    z-index: 101;
    top: 0;
    margin-right: 20px;
}

.Header .header-button:focus{
    outline: none;
}

.header-button.show {
    color: var(--primary-text);
}

.Header-container {
}

.header-button:before,  
.header-button:after {
    content: "";
    width: 40px;
    height: 3px;
    background: var(--header-text);
    position: absolute;
    right: 20px;
    border-radius: 100px;
    transition: all .25s ease;
}

.header-button:before {
    transform: translateY(-7px);
}

.header-button::after {
    transform: translateY(7px);
}

.header-button.show:before {
    transform: rotate(45deg);
    background: var(--primary-text);
}

.header-button.show:after {
    transform: rotate(-45deg);
    background: var(--primary-text);
}

.collpased-nav-bar {
    background-color: var(--background);
    padding-left: 5vw;
    position: absolute;
    z-index: -1;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    transition: all .25s ease;
}

.collpased-nav-bar.show {
    opacity: 1;
    z-index: 100;
}

.collpased-nav-bar.hide {
    opacity: 0;
    z-index: -1;
}

@media only screen and (max-width: 600px) {
    .Header .header-name {
        font-size: 15px;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .Header .header-button {
        font-size: 20px;
    }
}